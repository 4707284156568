export default {
  header: {
    profile: "个人账户",
    loginButton: "登录",
    registrationBtn: "注册账户",
    feedback: "联系我们",
    language: "语言",
    darkTheme: "深色主题",
    languageToggle: {
      en: "英文",
      ru: "俄语",
      cn: "中文",
      tr: '土耳其语',
      es: "西班牙语",
      hi: "印地语"
    }
  },
  profileNavMenu: {
    goToProfile: "进入个人账户",
    activeNumber: "已收到的电话号码",
    queryStatistics: "查询记录",
    balanceHistory: "账户流水",
    tariffs: "费率",
    settings: "设置",
    balance: "账户余额",
    topupBalance: "充值",
    topupAlert: "",
    logout: "推出",
    referralProgram: "合作伙伴计划",
    saleAgreement: "销售许可证",
  },
  profile: {
    activeNumbers: {
      title: "已收到的电话号码",
      noActiveNumbers: '',
      serverError: '服务器错误',
      status: "动态",
      waitingForSms: "正在等待短信",
      time: "时间",
      banned: "被拦截了码？",
      cancel: "取消",
      message: '代码已接收并已付',
      messageMultipleSms: '剩下的时间内您可以免费收该服务项下的新代码',
      blockModalMessage1: '改电话号码被发验证码的网站屏蔽了吗<br />如果是这样，请告诉我们。我们将取掉发放该电话号。',
      blockModalMessage2: '资金会立刻退换到您的个人账户上。<br />试一下再买这个国家或者另外一个国家的新电话号码',
      blockBtn: '是的，电话号被屏蔽了',
      moreSms: "",
      availableIn: "即将生效: ",
      waitingSms: "在等待接收短信"
    },
    queryStatistics: {
      title: "查询记录",
      noQuery: "没有查询",
      buyFor: "……购买",
    },
    balanceHistory: {
      title: "账户流水",
      outgoing: "开支记录",
      incoming: "充值记录",
      showMore: "显示更过",
      noHistory: "没有记录",
      numberOrder: "……号付款单项下的付款",
    },
    tariffs: {
      title: '费率',
    },
    settings: {
      title: "设置",
      sound: "收验证码时播放声音",
      infoAboutServices: "显示正在进行的购买信息",
      twoFactorEnabled: "双重验证",
      infoAboutBalance: "余额底线邮件通知",
      apiKey: "API代码",
      message: "请形成API代码，便于通过API和网站互动",
      buttonUpdate: "更新API代码",
      updateError: "更新错误",
      settingsChanged: "设置已更改",
      errorChangingSettings: "设置更改错误",
      phoneNumber: "您的电话号码",
      playceholderPhone: "请输入您的电话号码",
      refParams: {
        refLink: "合作伙伴计划的报告：",
        refOptions: "合作伙伴计划设置：",
      },
    },
    myPersonalInformation: {
      title: "您的个人信息",
      yourEmail: "邮箱",
      yourName: "名字",
    },
    referralProgram: {
      title: "合作伙伴计划",
      referralLinkTitle: "合作伙伴计划链接",
      buttonCopy: "复制",
      buttonDownload: "下载",
      topUpAmount: "您的合作伙伴消费",
      youHaveAlreadyReceived: "合作伙伴计划项下的收款",
      awaiting: "合作伙伴计划余额",
      detailing: "合作伙伴计划系统的报告",
      derived: '其中已提取',
      formTitle: "提交申请",
      formButton: "提交提取申请",
      participatePromotion: "参与推广活动",
      earnedYourReferrals: "通过您的推荐赚取",
      instructions: "将此注册链接发送给朋友或将其用于流量仲裁。 根据要求，以方便您的方式从余额中提取推荐费—默认推荐营业额的5％。 应计资金可以用于购买数字（根据请求向支持服务转移99₽的金额）或提取到卡或电子钱包。 提款到加密钱包—从100USDT到卡—从5,000₽。"
    }
  },
  profilePay: {
    sectionBalanceReplenishment: {
      title: "充值",
      cardButtonSelect: "选择",
      paymentTitle: "确认支付",
      paymentMessage: "一定要通过网站界面进行付款。只有通过点击付款按钮您才能将钱转至正确的帐号上。",
      paymentMessageAlipay: "该服务仅支持中国大陆地区的用户验证。 您可以尝试以下所有支付宝用户的方法",
      paymentPlaceholder: "请输入金额 *",
      paymentButton: "付款",
      paymentErrorAmount: "充值金额一定要大于零",
      payComment: "充值手续费",
      plasticCard: "银行卡：仅限于俄罗斯  🇷🇺",
      minAmount: "最低付款金额{minPrice}俄罗斯卢布",
      minAmountMessage: "最低付款金额{minPrice}俄罗斯卢布",
      payMessageForPayeer: "选择该充值方式，grizzlysms.com服务将由THREETWOTWO LTD公司提供。为了继续，您必须同意下列文件。",
      payMessageForPaypalych: "银行卡：只有俄罗斯  🇷🇺 (alt №3)",
      som: '银行卡：世界各地-方法 №2',
      payMessageForNut: "银行卡：世界各地-方法 №3",
      stripe: "银行卡：世界各地-方法 №1 (条纹)",
      bankCardsTemplateRussia: "银行卡：只有俄罗斯  🇷🇺 (alt №2)",
      payMessageForTether: "注意：汇款款金额要跟支付系统形成的金额一直。不然资金入不了账户，而退款费用是10美金",
      alipayTitle: "用支付宝付款请扫码：",
      alipayText: "...请给在线客服发一下您注册的时候用的邮箱，添加付款凭证的截图（↘️）<br/>余额无法自动充值。<br/>最低充值金额20人民币。",
      paymentMessageForConvertor: "付款金额, {currency}:",
      paymentMessageForConvertorWithCommission: "含手续费的付款金额, {currency}:",
      willBeCredited: "充值金额, {currency}:",
      messageForConverterDollars: "我们接受美元并按汇率兑换成卢布: <span> {rate} </span> 每美元",
      getNumbersFor: '获取电话号码 {name}',
      usdHelpMessage: '仅供参考。请优先考虑付款工具界面上显示的金额。由于汇率波动，金额可能略有不同。'
    },
    aliPay: {
      title: "支付宝和QQ支付",
      messageStep1: "1、点击下面的链接，选择充值余额，然后用支付宝或QQ支付:",
      messageStep2: "2、为了充值余额，请输入支付之后收到的卡密:",
      send: "发送",
      couponNotFound: "找不到優惠券",
      placeholderPassword: "卡密",
      waitingForRedirectPayment: "等待過渡到網站進行付款",
      paymentCodeNotCorrect: "支付碼不正確",
      buttonGetLink: "用支付宝或QQ支付，并接验证码",
    },
    cryptoPayments: {
      label: "加密付款"
    }
  },
  servicesList: {
    "Выбор_страны": "選擇國家",
    "Выбор_страны_для": "選擇 {name} 的國家/地區",
    title: "请选择国家",
    titlePopular: "受欢迎的国家",
    chooseAnother: "请选择另外一个国家",
    countryInputPlaceholder: "请选择国家",
    selectedLable: "选择",
    searchInputPlaceholder: "服务搜索",
    buttonWaiting: "稍等一下……",
    buttonBuy: "收",
    buttonRent: "租金",
    message: '暂时没有该国家的电话号',
    buttonUpdate: '更新',
    quantity: '个',
    quantityFew: '缺货',
    getItFor: "獲取",
    numberTemporarilyUnavailable: "这些数字只能通过API获得",
    orderFrom100numbers: "购买超过100个号码联系客服",
    labelCountryHighPercentDelivery: "接码比例比较高的国家",
    labelSelectOtherCountry: "选择别的国家",
    showMore: "显示更多",
    from: '起',
    retail: '零售价',
    authRetail: '您的报价',
    wholesale: '批发',
    telegramMessagePrice: '批发价频道:',
    telegramMessageBot: 'TG小应用:',
    telegramMessageNews: '本站最新消息:',
    scrollBtn: '所有的服务和国家',
    selectCountry: "選擇國家",
    selectService: "選擇服務",
    relatedArticlesFor: "關於 «{name}» 的文章",

    requestNewServiceForm: {
      title: '添加新服务项目',
      subTitle: '没找到合适的服务项目？请告诉我们更详细一点，我们将会进行更改。<br />  我们建议你马上使用这个栏目。',
      anyother: '其它',
      dataAboutSite: '网站地址 *',
      smsMessage: '短信内容 *',
      countryService: '期望的国家（可选）',
      price: '电话吗价格（可选）',
      btnSend: '发送',
      backToList: '查清单',
      offerServiceBtn: "推荐服务项目",
    },
    linkToAccount: {
      btnMsg: "{accountName} 帐目",
      text: "与现成的工作 {accountName} 帐户需要PC技能;可用国家/地区的集合与帐户自我注册的国家/地区的集合不同"
    }
  },
  languagesList: {
    "Выбор_сервиса": "选择服务",
    "Поиск_по_сервисам": "按服务搜寻",
    "Выберите_услугу": "请选择服务",
    "Поиск_по_странам": "按国家搜索",
    "Успешная_покупка_номера": "号码已购买成功。该电话号码即将显示在已收到的电话号码项目里面。",
    serviceNotFound: '未找到服务',
    repeatMsg: '等等！ < {min} 敏',
    repeatModal: {
      attempt: '尝试',
      from: '出',
      "Долгое_ожидание": '漫长的等待？',
      "Предложение_ускорить_поиск": '我們試著找一間更貴的房間吧。只有在收到簡訊後才會最終扣除資金。您可以隨時取消該號碼或不在服務申請中輸入該號碼。附加費越高，更快找到房間的機會越大。',
      lowBalanceMessage: '资金不足',
    },
    errorMessages: {
      "NO_NUMBERS": "该号码不可用。请再试",
      abort: "号码搜索停止",
    }
  },
  blog: {
    title: '博客',
    buttonDetails: "了解更多",
    pageNumber: "页码",
    readMore: '类似文章',
    getNumberFor: "获取号码"
  },
  footer: {
    title: "最好的在线短信接码平台。",
    userAgreement: "公开报价",
    referralProgram: "合作伙伴计划",
    returnsPolicy: "退款政策",
    personalInformation: "隐私和Cookie政策",
    messageDisabledRussianNumbers: "来自俄罗斯的电话号码不出售",
    footerDisabledRussianNumbers: "由于联邦法律的要求，俄罗斯移动运营商的用户号码不提供临时使用 \"关于通讯\" 2003年7月7日N126-FZ，并根据2020年2月12日俄罗斯联邦政府法令批准的公共通讯网络集中管理规则第5段g分段 \"关于批准《公共通信网集中管理规则》\""
  },
  registration: {
    title: '注册账户',
    registrationSuccessMessage: "用户注册成功！确认注册的链接已发送到邮箱",
    nameInputPlaceholder: '名字 *',
    emailInputPlaceholder: '邮箱 *',
    passwordInputPlaceholder: '密码 *',
    passwordConfirmInputPlaceholder: '确认密码 *',
    buttonRegistration: '注册',
    buttonRegistrating: '稍等一下……',
    passwordsMismatchMessage: "两次密码不一致",
    backHome: '放回首页',
    loginWithSocialNetworks: "登录方式：",
    orRegistrartion: "或注册",
    iAgree: "我了解并接受",
    privacyPolicy: "隐私和Cookie政策",
    userAgreement: "公开报价服务条款",
    alreadyHaveAccount: "已有账户吗？",
    login: "登录",
    messageSuccessConfirmRegistration: "用户注册成功",
  },
  authorization: {
    title: "登录",
    emailInputPlaceholder: '邮箱',
    passwordInputPlaceholder: '密码',
    tokenInputPlaceholder: '验证码',
    buttonRegistration: '登录',
    buttonRegistrating: '稍等一下……',
    or: '或',
    newUser: '新用户吗？',
    registration: '这册',
    forgotPassword: "忘记密码？",
  },
  resetThePassword: {
    title: "找回密码",
    buttonReset: '找回密码',
    buttonWaiting: '稍等一下……',
    emailInputPlaceholder: '邮箱',
    message: "找回密码的链接已发送至您邮箱。请检查您的邮箱并按照邮箱里面的说明进行操作。"
  },
  errorsValidationForms: {
    requiredForFill: "必填",
    emailErrorMessage: "邮箱不正确",
    requaredLengthMessage: "最少字符数："
  },
  advantagesBlock: {
    title: ""
  },
  aboutBlock: {
    showMoreBtn: "显示更多",
    rollUp: "最小化",
  },
  serviceCard: {
    availableNumbers: "现有的电话号",
    price: "价格",
    buyNow: "收",
    emptyCountryListMessage: "未找到该项目电话号码.<br /> 稍后再试"
  },
  feedbackForm: {
    title: "联系我们",
    namePlaceholder: "名字 *",
    emailPlaceholder: "邮箱 *",
    messagePlaceholder: "您的信息 *",
    iAgree: "我了解并接受",
    privacyPolicy: "隐私和Cookie政策",
    userAgreement: "公开报价服务条款",
    buttonSend: '发送',
    buttonRegistrating: '稍等一下……',
    sendError: "发送错误",
    applicationIsAccepted: "您的申请已收到"
  },
  copyToBuffer: {
    success: "内容复制成功",
    error: "复制错误",
  },
  breadcrumbs: {
    homePage: "首页",
    blog: "博客",
  },
  confirm: {
    passwordReset: "找回密码",
    newPassword: "新密码",
    confirmPassword: "确认密码",
    confirm: "确认",
    authorization: "登录",
    notMatch: "两次密码不一致",
    changedSuccessfully: "密码更改更攻",
  },
  errorPage: {
    pageNotFound: '未找到页面',
    somethingWentWrong: '出了点问题',
    backHomepage: '放回首页'
  },
  support: {
    inputSearch: {
      placeholder: "输入你的问题",
    }
  },
  successPayment: {
    successMsg: "付款成功",
    waitMsg: "付款正在进行中",
    backHomepage: '首页'
  },
  searchPage: {
    placeholder: '搜索',
    showMore: "显示更多",
    nothingFoundMsg: "未找到适合查询的信息",
    'Сервисы': '服务',
    'Статьи': "文章",
    'Страницы': "页面",
    'Все результаты': "所有的查询结果",
  },
  common: {
    "downloadFile": "文件下载",
    "temporarily_unavailable": "暫時無法使用"
  },

  lastArticlesBlock: {
    title: "博客",
    textBtn: "博客",
  },

  reviewsBlock: {
    title: '用户评价',
    addReviewBtn: '留评',
    yourName: '名字',
    messageTextarea: '请您留评',
    sendReview: '提交评价',
    success: '评价提交成功',
    validNameMsg: '名字',
    validMsg: '输入信息',
  },

  currencyToggle: {
    tooltipCurrency: '美元报价仅供参考。账户结算一律使用卢布进行。'
  },

  pagination: {
    showing: '显示',
    of: '从',
  },

  additionalCashbackAccrual: {
    message: "第一次充值大于1000卢布收 <span>加10%</span> 回款 <span>{{balance}}</span> !",
    buttonCancel: "充值",
    buttonPayment: "充值"
  },

  countries: {
    title: '可用的国家',
    countries: '国家',
    popularServices: '热门的服务',
    anotherServices: '请选择服务'
  },

  partnerPage: {
    becomePartner: "成为合作伙伴",
    popUp: {
      title: "成为合作伙伴",
      yourContactPlaceholder: "您的聯繫人（電報、微信、WhatsApp、電子郵件、其他）",
      countryPlaceholder: "SIM 卡的“原產國”",
      equipmentPlaceholder: "你的設備",
      equipmentPortsPlaceholder: "硬件端口數",
      aboutPlaceholder: "關於您，我們還需要了解什麼？",
      send: "發送",
      accept1: "我同意",
      accept2: "隱私政策和所請求數據的使用",
      errorForm: "發生了一個錯誤。請稍後重試",
      successForm: "申請成功發送",
    }
  },

  partnerFormBlock: {
    title: "成为合作伙伴",
    contactTitle: "你的TG",
    contactPlaceholder: "您的首选联系人",
    countrySimCard: "的国家 \"origin\" SIM卡的",
    countrySimCardPlaceholder: "逗号分隔",
    yourEquipment: "您的设备",
    yourEquipmentPlaceholder: "详细告诉我",
    countPort: "硬件端口数",
    countPortPlaceholder: "以数字指定",
    message: "我们还需要了解你什么？",
    messagePlaceholder: "详细描述您的业务",
    accountGrizzlySmsEmail: "灰熊短信帐户（您使用的电子邮件地址"
  },
  partnerHeadingBlock: {
    becomePartner: "成为合作伙伴",
    yieldCalculator: "收益率计算器"
  },
  partnerCalculatorBlock: {
    country: "国家",
    priceSim: "SIM卡费用",
    countPort: "调制解调器中的端口数",
    netProfit: "Profit",
    netProfit3: "盈利3个月",
    netProfit6: "6个月的收入",
    netProfit12: "12个月的收入",
  },
  generalSearch: {
    searchByCountry: "按国家/地区搜索",
    searchByService: "按服务搜寻",
    title: "接收短讯的虚拟号码价格",
    subtitle: "在下表中选择您需要的服务或国家/地区",
    tableHeaderService: "服务",
    tableHeaderCountry: "国家",
    tableHeaderCurrency: "批发价格",
    tableHeaderAmount: "数量",
    tableHeaderNumberReceived: "% number",
    tableHeaderSmsReceived: "% sms",
    tableHeaderActivation: "激活 %",
  },
  profileLoyaltyProgram: {
    cashback: {
      title: "现金回赠",
      week_deposit: "本周的追加金额",
      projectedCashback: "预测现金返还",
      tillNextRank: "直到下一个等级离开",
      cashbackPercentNextRank: "下一级现金返还",
      rang: "职级"
    },
    history: {
      title: "参与历史",
      message1: "供补货",
      message2: "现金回赠",
    },
    programConditions: {
      title: "忠诚计划条款",
      rangLeft: "等级：获得条件",
      rangRight: "%现金回赠",
      rangNotLeft: "没有排名：上榜 > 0 <1,000 ₽",
      rangNotRight: "没有现金返还",
      message: "现金返还记入用户 <span>每周第一天(星期一)</span> 根据过去一周存款总额的结果。 现金返还的百分比取决于用户在上周末达到的排名",
      replenishment: "补货/补货",
      cashback: "现金回赠"
    }
  },
  license: {
    licenseSuccess: '许可证已购买并发送到您的电子邮件',
    buyKey: '购买钥匙 GrizzlySMS'
  },
  featuresTextBlock: {
    getIndividualNumber: "获取一个私人号码"
  },
  sortComponent: {
    country: '最好的国家'
  },
  rentNumbers: {
    typeViews: {
      "number_activation": "激活",
      "rent_number": "出租",
      "readyAccounts": "现成的账户"
    },
    rentInformation: {
      "rental_period": "租期",
      "rental_duration": "租期",
      "rent": "租金",
      "the_clock": "按小时计算",
      "for_days": "白天",
      "specify_rental_duration": "指定租期",
      "maximum_number_hours": "最大小时数",
      "minimal_number_hours": "最少时数",
      "number_successfully_rented": "成功租用号码",
      "selected_time_not_available": "号码不可用",
      "available_minimum_rental_period": "最低租期",
      "available_maximum_rental_period": "可供租用的最长租期",
    },
    myRentNumbers: {
      "rent_virtual_number": "租用虚拟号码",
      "active": "活动中",
      "received": "收到",
      "returned": "已取消",
      "not_found": "未找到"
    },
    rentNumberCard: {
      "canceled": "已取消",
      "expires_in": "到期于",
      "extension": "延伸",
      "messageTitle": "信息",
      "messageMessage": "还没有消息或电话到达。 发送短信到号码或呼叫，信息将立即显示在这里.",
      "successCancel": "号码租赁成功取消",
      "successNumberRenewal": "号出租成功延长",
      "rentalPeriodExpired": "已过期"
    },
    readyNumbers: {
      message: '重要！ 如果您最初购买现成的帐户，您将需要查看 <a href=\"{link}\"> \"帐目\"</a> 关于 \"支援(常见问题)\" 页面.',
      ok: '同意。',
      toFaq: '常见问题',
      cancel: '取消'
    },
    popUpExtendLease: {
      "extend_number_rental": "延长号码租赁",
      "going_renew_lease_number": "你打算续租一个号码吗?",
      "renewal_history": "更新历史",
      "date": "日期",
      "price": "价格",
      "term_hours": "以小时为单位的时间限制",
      "number_rental_renewal_period": "号码续租期",
      "lease_successfully_extended_until": "租约成功延长至",
      "close": "接近",
      "short_day": "天数",
      "short_hours": "时间.",
      "short_minutes": "敏."
    },
    helpPopUp: {
      message: `<h4>号码激活</h4>
          <p>如果您在前20分钟内没有收到带有代码的短信，您可以取消该号码，这笔钱将全部退回您的帐户。</p>
          <h4>租一个号码</h4>
          <p>在所选期间，您将始终有机会从所选服务接收无限数量的短信。</p>
          <p>您可以随时恢复访问您的注册帐户!</p>
          <p>如果您在前20分钟内没有收到带有代码的短信，您可以取消该号码，这笔钱将全部退回您的帐户。</p>
          <p>如果您没有收到短信并且超过20分钟已经过去，则无法取消租赁。</p>
          <p>租期届满后，售出的号码将不会转售相应的服务。</p>
          <p>最短租期为4小时，最长为4周。</p>
          <p>租用号码有两种选择:</p>
          <p>完全租赁-在这种情况下，您可以收到所有短信，其服务在我们的目录中提供。</p>
          <p>租用特定服务的号码。 在这种情况下，您将只从您选择的服务收到短信。</p>

         <h4>准备好的账户</h4>
         <p>不想浪费时间等短信?您可以购买我们合作伙伴开立的现成账户。Telegram、VK和其他服务的账户通常都是可用的。</p>
         <p>Telegram账户提供两种格式:TDATA(这种格式的账户可以在PC上登录，然后转移到移动设备上)和json+session(在这种情况下你需要特殊的软件)。</p>
         <p>使用现成帐户的细节，以及返回条件，在FAQ部分(网站菜单中的“支持”按钮)中有描述。请务必在购买前阅读此信息!</p>`
    }
  },
  comments: {
    "add_comment": "添加评论",
    "comments": "评论",
    "collapse": "崩溃",
    "expand": "扩张",
    "send_comment_moderation": "您的评论已被发送为适度.",
    "ony_auth_user": "只供注册会员使用"
  },
  saleAgreement: {
    "title": "销售许可证",
    "name": "姓名",
    "type_po": "软件类型",
    "price": "价格",
    "date_purchase": "购买日期",
  },
  freeNumbersPopUpRegistration: {
    subtitle: "创建一个免费号码的帐户不起作用? 注册并为服务选择一个私人号码 — <span>从 $0.01!</span>",
    button: "试试"
  },
  accountShop: {
    mainSectionInfo: {
      my_paid_accounts_title: "我的付费帐户",
      my_paid_accounts_message: "在这里，您可以看到所有已购买帐户的列表以及所需的登录信息",
      my_paid_accounts_button: "采购帐目",

      purchasing_ready_accounts_title: "采购帐目",
      purchasing_ready_accounts_message: "我们知道，在20分钟内收到短信并不总是可能的。 在本节中，您可以为任何热门服务购买现成的帐户。",
      purchasing_ready_accounts_button: "我的付费帐户",
    },
    selectedService: {
      title: "选择服务",
      search_placeholder: "按服务名称搜索"
    },
    selectedOffer: {
      title: "选择一个项目"
    },
    dialogOffer: {
      title: "购买帐户",
      name_of_product: "产品名称",
      description_of_product: "产品说明",
      characteristics: "特征",
      registration_method: "注册方法",
      country: "国家",
      account_format: "帐户格式",
      availability_cloud_password: "云密码",
      information_about_purchased_product: "有关购买产品的信息",
      download_files: "下载档案",
      buy_more: "购买更多",
      yes: "是的",
      no: "非也。",
      minimum_order_quantity: "最低订购数量"
    },
    myServices: {
      messageAuth: "您需要登录到您的帐户",
      messageEmpty: "没有购买帐户"
    }
  },

  partner: {
    breadcrumbs_title: "合作伙伴",
    documentation: "文件",
    activations_history: {
      activations_history: "激活历史",
      filter: "过滤器",
      export_in: "出口至",
      number: "电话号码",
      load_more: '展示更多',
      total_rows: '总计: {{ count }} 行',
      select_status: '选择状态',
      date_activation: '激活日期',
      date_from: '数据来自',
      date_to: '日期至',
      ready: '准备好了',
      canceled: '已取消',
      activation: "激活"
    },
    sim_card: {
      sim_card: "Sim卡",
      port: '港口',
      info: '资料',
      earned: '赚来的',
      countServices: '统计服务'
    },

    price_regulator: {
      price_regulator: "价格监管机构",
      select_country_started: "选择一个国家开始",
      services: "服务",
      search_service: "按服务搜寻",
      all_services: "所有服务",
      all_countries: "所有國家",
      popular_services: "受欢迎的",
      market_price: "设定市场价格",
      competitors_prices: "显示竞争对手价格",
      pice_successfully_changed: "价格成功改变",
    },

    withdrawal_founds: {
      withdrawal_founds: '撤回资金',
      balance: '您的余额',
      order_payments: '订购付款',
      currency_type: '货币类型',
      withdrawal_method: '提取方法',
      order_status: '订单状况',
      select_method: '选择一种方法',
      select_status: '选择状态',
      requisites: '必要事项',
      sum: '总和',
      currency: '货币',
      date: '日期',
      status: '状况',
      nothing_found: '什么也没找到',
      amount_in_currency: '货币金额',
      wallet_card_number: '钱包/卡号',
      comment: '评论',
      not_necessary_placeholder: '没有必要',
      all_btn: '全部',
      error_required: '填写所有必填字段',
      success_msg: '你的申请已被接受',
      select_currency_type: "選擇貨幣類型",
      warning_message: '<span>重要！</span> 请确保您在上面创建应用程序时输入了正确的付款详细信息\uFE0F. 的管理grizzlysms.com 如因付款资料不正确而导致资金损失，概不负责。'
    },

    statistic: {
      statistic: '货币化统计',
      average_SIM: '平均 <br/> 每个SIM',
      top_services: '顶级服务 <br/>24小时',
      activations_count: '激活计数 <br />24小时',
      last_24_hours: '最后 <br/>24小时'
    },
    dialog_competitors_prices: {
      your_price: "你的价格",
      competitors_prices: "竞争对手的价格"
    },
  },
  newPayments: {
    minAmount: '最低付款金额:',
    commission: '手续费:',
  },
  soft: {
    "submit_application": "递交申请",
    "search_by_name": "按名称搜寻",
    "all_services": "全部<br/>服务",
    "activations": "激活"
  },
  productLicensesBlock: {
    availableAt: "可于",
  },
  documentationApi: {
    rent: "空气污染指数租金",
    tables: "表格",
    activations: "API激活",
    expandContent: "展开内容",
    collapseContent: "折叠内容",
    successLinkCopy: "链接复制成功",
    successApiKeyCopy: "API密钥复制成功",

    select: "選擇",
    fillIn: "填寫",
    result: "結果",
    execute: "執行",
    urlRequest: "請求網址",
    resultLoading: "正在加載結果...",
    testMethodNow: "現在測試這個方法"
  },
  marketplaceRegistrationPage: {
    pageTitle: "店铺注册",
    formInputs: {
      name: {
        label: '为你的商店起一个名字。或许你的称呼能体现出你产品的本质，对买家的选择有帮助。',
        placeholder: '姓名',
        errorMessage: '需要填写'
      },
      shopDescription: {
        label: '店铺描述',
        placeholder: '对商店进行描述，突出其独特性和特色......',
        errorMessage: '需要填写'
      },
      email: {
        label: '您的电子邮件',
        placeholder: '请输入您的电子邮件地址以获取反馈...',
        errorMessageForNull: '需要填写',
        errorMessageNoValid: '需要填写'
      },
    },
    formSendButton: '注册店铺',
    rulesMarket: {
      iAgree: '我同意',
      rulesPhrase: 'Grizzlysms.com 市场规则和规定'
    },
    offerAgreement: {
      iAccept: '我接受',
      offerPhrase: '要约协议'
    },
    toastSuccessMessage: '您的申请已发送'
  },
  bannerForBuyAccounts: {
    title: '短信很难激活吗?',
    subtitle: '购买一个现成的帐户，这是容易得多！',
    button: '购买现成帐户'
  }
}
