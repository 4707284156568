export default (context, inject) => {
  const convertCurrency = (price, defaultCurrency = '', {
    isRubToUsd
  } = {}) => {
    const currentCurrency = context?.store?.state?.currency?.currentCurrency;
    const currentCurrencyValue = context?.store?.state?.currency?.currencyConvertValue;
    const cookies = context?.$cookies.get('currency');

    if (String(currentCurrencyValue || "0") === "0") {
      return ""
    }

    const currencyInit = cookies || currentCurrency;
    // конвертируем из рублей в доллары
    if (defaultCurrency && defaultCurrency === 'rub' && !isRubToUsd) {
      let _val = (price / currentCurrencyValue);
      _val = Math.abs(_val) < 0.01 && _val !== 0 ? Math.sign(_val) * 0.01 : _val;

      return _val.toFixed(2);
    }

    // конвертируем из долларов в рубли
    if (defaultCurrency && defaultCurrency === 'usd' && !isRubToUsd) {
      return (price * currentCurrencyValue).toFixed(2);
    }

    if (currencyInit === 'usd' || isRubToUsd) {
      let _val = (price / currentCurrencyValue);
      _val = Math.abs(_val) < 0.01 && _val !== 0 ? Math.sign(_val) * 0.01 : _val;

      return _val.toFixed(2);
    }

    return price;
  }
  inject('convertCurrency', convertCurrency)
}
