








































import Header from "~/components/common/Header.vue";
import Footer from "~/components/common/Footer.vue";
import {Component, mixins, Watch} from "nuxt-property-decorator";
import CustomToasted from "~/components/common/CustomToasted.vue";

import {
  AppMixin,
  CmsMixin,
  UserAuthMixin,
  UserMixin
} from "~/node_modules/sitis-nuxt/lib/mixins";

import languagesInfo from '~/constant/languiges-info.json';
import getCanonical from "~/helpers/canonical";
import {viewportUpdateDetectBreakpoint} from "~/helpers/viewport";
import utilsGetOgUrl from "~/helpers/utilsGetOgUrl";

const PASS_REQUEST_TIMESTAMP = "PASS_REQUEST_TIMESTAMP";

@Component({
  head: function (this: LayoutDefault) {
    const meta: any = this.headerMetaAsync;
    const alternate = this.getAlternateLink;

    const lang = this?.$i18n?.locale || this?.$i18n?.defaultLocale || "en";

    return {
      __dangerouslyDisableSanitizers: ['script', 'innerHTML'],
      script: [
        {
          hid: 'test-script',
          innerHTML: `window.ChatraSetup = {language: '${lang}'};`,
          type: 'text/javascript',
          charset: 'utf-8'
        }
      ],

      htmlAttrs: {
        lang: this.$hreflangInitial(),
      },

      titleTemplate: ['%s', (this.appCfg?.suffixDivider || ""), (this.appCfg?.suffixTitle || "")].filter((t) => !!t).join(' '),

      meta: [...meta],
      link: [
        ...alternate,
        // { rel: 'canonical', href: this.getLinkCanonical },
      ],
    };
  },
  components: {
    Header,
    Footer,
    CustomToasted,
    MobileMenu: () => import( "~/components/common/MobileMenu.vue"),
    LoadingModal: () => import("~/components/common/LoadingModal.vue"),
    ButtonEditCmsContent: () => import("~/components/layouts/ButtonEditCmsContent.vue"),
    WidgetAdmin: () => import( "~/node_modules/sitis-nuxt/components/widget-admin/WidgetAdmin.vue")
  },

  created(this: LayoutDefault) {
    if (process.client) {
      const localeCode = this?.$i18n?.locale || "en";
      const cookieCurrency = this.$cookies.get('currency');
      const isCookieCurrency = Boolean(String(cookieCurrency) && cookieCurrency != undefined && cookieCurrency != null);
      if (localeCode !== 'ru' && !isCookieCurrency) {
        this.$store.commit("ab-test/setCookiesAbTest", "ab-test__hide-ruble-currency");
      };


      const isWholesale = this.$cookies.get('isWholesale') || false;
      const currency = this?.$cookies?.get('currency');

      if (currency) {
        this.$store.commit("currency/setCurrency", currency);
      }
      this.$store.commit("services/isWholesaleToggle", isWholesale);

    }
  },

  async fetch(this: LayoutDefault) {
    if ((this.$store.state["languages-list"]?.servicesPopular || []).length <= 0) {
      await this.$store.dispatch("languages-list/getPopularServicesList");
    }
    if ((this.$store.state["services"]?.services || []).length <= 0) {
      await this.$store.dispatch("services/getListServices", {
        isResetPagination: true,
        isLoadMore: false,
      });
    }
  },

  computed: {
    headerRef: function () {
      return this.$refs.refHeader
    },
    isWidgetAdmin: function (this: LayoutDefault) {
      return Boolean(true)
    },
    isPopUpLoading: function (this: LayoutDefault) {
      return this.$store.state?.loading?.popUpLoading || false
    },
  },
})
export default class LayoutDefault extends mixins(
  AppMixin,
  UserMixin,
  UserAuthMixin,
  CmsMixin
) {
  $modal: any;
  isShowMenu: boolean = false;
  $i18n: any;
  ChatraSetup: any;
  $cookies: any;
  $api: any;
  $hreflangInitial: any;
  $recaptcha: any;
  langInfo: { [key: string]: any } = languagesInfo;
  $nuxt: any;
  _adminIdentity: any = null;
  findChatraTimeout: any = null;

  async mounted(): Promise<void> {
    await this.$store.dispatch('currency/setCurrencyConvertValue');

    const currentLocale = this.$api.configProps.headers?.['x-user-locale'] || '';
    if (!currentLocale) {
      const locale = this.$i18n.locale;
      this.$api.setLocale(locale);
    }

    const host = '_' + window.location.host;
    if (host) {
      window.document.body.classList.add(host.replace('.', ''));
    }

    if (this.$i18n.locale === 'en') {
      this.$recaptcha.language = 'en';
    }
    if (this.$i18n.locale === 'cn') {
      this.$recaptcha.language = 'zh-TW';
    }
    if (this.$i18n.locale === 'tr') {
      this.$recaptcha.language = 'tr'
    }
    if (this.$i18n.locale === 'ru') {
      this.$recaptcha.language = 'ru'
    }

    const fullPath = this.$route.fullPath || "";
    const show_rent = String((fullPath.split("&")?.[0] || "").split("show_rent=")?.[1] || "");
    if (typeof show_rent !== "undefined") {
      this.$cookies.set('show-rent', show_rent, {
        path: '/',
        maxAge: 60 * 60 * 24 * 60,
      })
    }

    const _currency = this.$cookies.get("currency");
    if (this.$i18n.locale === 'en' || this.$i18n.locale === 'cn') {
      this.$cookies.set('currency', 'usd');
      this.$store.commit('currency/setCurrency', 'usd');
    }
    if (this.$i18n.locale === 'ru' && !_currency) {
      this.$cookies.set('currency', 'rub');
      this.$store.commit('currency/setCurrency', 'rub');
    }

    // const lastUrlForPay = this.$cookies.get("lastUrlForPay");
    // if (!!lastUrlForPay) {
    //   this.$cookies.remove("lastUrlForPay");
    //   window.location.href = lastUrlForPay;
    // }

    const authToken = this.$cookies.get('authToken');
    if (!!authToken) {
      await this.getUser();
    } else {
      this.$store.commit('user/setLoadingUser', false)
    }

    if ((this.$store.state["services"]?.services || []).length <= 0) {
      await this.$store.dispatch("services/getListServices", {
        isResetPagination: true,
        isLoadMore: false,
      });
    }
    const isHasTelegramCookies = this.$cookies.get('telegram_or_phone');

    await this.$store.dispatch("commerceOrders/checkLastOrders");

    this._adminIdentity = this.$cookies.get('_adminIdentity');

    if (this.isLoggedIn && isHasTelegramCookies) {
      const settings = {
        telegram: isHasTelegramCookies
      }

      const res = await this.$api.agent.transport
        .put("users/settings", {settings})
        .catch((err: any) => {
        });

      if (res.data) {
        this.$cookies.remove('telegram_or_phone');
      }

    }

    window.addEventListener("resize", viewportUpdateDetectBreakpoint.bind(null, this.$store));
  }
  destroyed() {
    window.removeEventListener("resize", viewportUpdateDetectBreakpoint.bind(null, this.$store));
  }

  @Watch('userId')
  async onChangeAuthUser() {
    if (Boolean(this.userId)) {
      await this.findChatraInDom();
    }
  };

  async getCustomMenu(this: LayoutDefault) {
    // if (this.menu.length > 0) {
    //   return null;
    // }
    // await this.getMenu();
  }

  get baseApiUrl() {
    return process?.env?.baseApiUrl;
  }

  get footerMenu(): any[] {
    // Получить меню можно двумя способами(должна быть подключена миксина CmsMixin)
    // const allMenus = this.allCmsMenus;
    // const footer['footer'].items as any[];
    const footerMenu = this?.getCmsMenu("docs") || [];
    return footerMenu?.items as any[];
  }

  get countersFooter() {
    return this.appCfg?.counters?.footer || "";
  }

  get headerMetaAsync() {
    const config = this.$store.state?.appState?.config;
    const headMeta = config?.head?.meta || [];

    let meta: any = [];

    headMeta.map((item: any) => {
      meta.push({name: item.name, content: item.content});
    });

    meta = [
      ...meta,
      {
        hid: "og:title",
        name: "og:title",
        property: "og:title",
        content: this?.page?.page?.title || "",
      },
      {
        hid: "og:site_name",
        name: "og:site_name",
        property: "og:site_name",
        content: "GrizzlySMS",
      },
      {
        hid: "og:description",
        name: "og:description",
        property: "og:description",
        content: this?.page?.page?.description || "",
      },
      {
        hid: "apple-mobile-web-app-title",
        name: "apple-mobile-web-app-title",
        content: `apple-${this?.page?.page?.title || ""} `,
      },
      {
        hid: "og:image",
        name: "og:image",
        property: "og:image",
        content: `${this?.appCfg?.logoFront || 'logo'}`,
      },
      {
        hid: "og:url",
        name: "og:url",
        property: "og:url",
        content: utilsGetOgUrl({ context: this }),
      },
    ];

    return meta;
  }
  get getAlternateLink() {
    const routeName = this.$route.name || '';
    const skipNamePage = ['blog-slug'];
    if (skipNamePage.find((t) => String(routeName || '').includes(t))) {
      return []
    }

    const isProd = !process.env.isDev;

    const locale = this.$i18n?.locale || this.$i18n?.defaultLocale;
    let fullLink: string = this.$route.fullPath === '/' ? '' : this.$route.fullPath;
    fullLink = String(fullLink || '').split(`/${ locale }`).pop() || '';

    return Object.keys(this.langInfo).map((t) => {
      const domain = isProd ? this.langInfo?.[t].domain : this.langInfo?.[t].devDomain;
      return {
        rel: "alternate",
        href: `${domain}${fullLink}`,
        hreflang: this.langInfo?.[t]?.isDefault ? "x-default" : this.langInfo?.[t]?.locale === 'cn' ? 'zh' : this.langInfo?.[t]?.locale,
        hid: `alternate-default-${this.langInfo?.[t]?.locale}`
      }
    });
  }


  get getLinkCanonical() {
    const host = [this.$store.state["local-settings"]?.headers?.host, '/'].join('');
    const fullPath = (this.$route?.fullPath || '').substring(1);
    return getCanonical(host, fullPath);
  }

  get isUser() {
    return this.$store.state.user?.user
  }
  get userId() {
    return String(this.$store.state.user?.user?.id || "")
  }

  get currentHost() {
    const currentLocale: string = this.$i18n.locale;
    const _languagesInfo: any = {...languagesInfo};
    return String(_languagesInfo?.['ru']?.domain || '') || '';
  }

  updateChatra() {
    const _prefix = 'https://panel'
    const _host = this.currentHost.replace('https://', '') || window.location.host;
    const _userId = this.$store.state?.user?.user?.dynamicFields?.e_id;
    const _window: any = window || null;
    if (!_window) {
      return null
    }
    _window?.Chatra('updateIntegrationData', {
      email: `${this.$store.state.user?.user?.email}`,
      name: `${this.$store.state.user?.user?.name}`,
      'Баланс': `${this.$store.state["user-local"]?.balance || 0} ₽`,
      'Админка': `${_prefix}.${_host}/users?page=1&filter[id][in][]=${_userId}`,
      'Активации': `${_prefix}.${_host}/go-numbers?page=1&filter[user_id]=${_userId}`,
      'Транзакции': `${_prefix}.${_host}/transactions?page=1&filter[user_id]=${_userId}`,
    });
  }

  async findChatraInDom() {
    if (!this.isUser) {
      return
    }
    clearTimeout(this.findChatraTimeout);
    let chatraWrapper = null;
    this.findChatraTimeout = setTimeout(() => {
      chatraWrapper = document.getElementById('chatra__iframe-wrapper');
      if (!chatraWrapper) {
        this.findChatraInDom();
      } else {
        clearTimeout(this.findChatraTimeout);
        this.updateChatra()
      }
    }, 1000);
  }
}
