export default {
  header: {
    profile: "प्रोफ़ाइल",
    loginButton: "लॉग इन करें",
    registrationBtn: "साइन अप करें",
    feedback: "प्रतिक्रिया",
    language: "भाषा: हिन्दी",
    darkTheme: "डार्क थीम",
    languageToggle: {
      en: "अंग्रेजी",
      ru: "रूसी",
      cn: "चीनी",
      tr: "तुर्की",
      es: "स्पेनिश",
      hi: "हिन्दी"
    }
  },
  profileNavMenu: {
    goToProfile: "प्रोफ़ाइल",
    activeNumber: "प्राप्त संख्या",
    queryStatistics: "क्वेरी आँकड़े",
    balanceHistory: "शेष इतिहास",
    tariffs: "टैरिफ",
    settings: "सेटिंग्स",
    balance: "शेष राशि",
    topupBalance: "टॉप अप बैलेंस",
    topupAlert: "",
    logout: "लॉग आउट करें",
    referralProgram: "रेफरल कार्यक्रम",
    saleAgreement: "लाइसेंस की बिक्री",
  },
  profile: {
    activeNumbers: {
      title: "प्राप्त संख्या",
      noActiveNumbers: 'कोई सक्रिय संख्या नहीं',
      serverError: 'सर्वर त्रुटि',
      status: "स्थिति",
      waitingForSms: "एसएमएस का इंतजार",
      time: "समय",
      banned: "एक ब्लॉक में?",
      cancel: "रद्द करें",
      message: "कोड स्वीकार किया और भुगतान किया ।  कृपया कोड दर्ज करें",
      messageMultipleSms: 'शेष समय में, आप सेवा से नए कोड मुफ्त में प्राप्त कर सकते हैं',
      blockModalMessage1: 'यह संख्या सेवा पक्ष पर अवरुद्ध थी?<br /> हमें इसके बारे में बताएं और हम खोज परिणामों से नंबर निकाल देंगे',
      blockModalMessage2: 'धनराशि तुरंत शेष राशि में वापस कर दी जाएगी.<br /> उसी या किसी अन्य देश में एक नया नंबर खरीदने की कोशिश करें',
      blockBtn: 'हां, संख्या अवरुद्ध है',
      moreSms: "अधिक एसएमएस",
      availableIn: "में उपलब्ध है: ",
      waitingSms: "नए एसएमएस का इंतजार"
    },
    queryStatistics: {
      title: "क्वेरी आँकड़े",
      noQuery: "कोई प्रश्न नहीं",
      buyFor: "के लिए खरीदें"
    },
    balanceHistory: {
      title: " शेष इतिहास",
      outgoing: "निवर्तमान",
      incoming: "आवक",
      showMore: "अधिक दिखाएँ",
      noHistory: " कोई इतिहास नहीं",
      numberOrder: "चालान द्वारा भुगतान",
    },
    tariffs: {
      title: 'टैरिफ',
    },
    settings: {
      title: "सेटिंग्स",
      sound: "कोड प्राप्त करते समय ध्वनि चलाएं",
      infoAboutServices: "सक्रिय खरीद के बारे में जानकारी दिखाएं",
      twoFactorEnabled: "दो-कारक प्रमाणीकरण",
      infoAboutBalance: "ईमेल अधिसूचना के लिए कम शेष सीमा",
      apiKey: "एपीआई कुंजी",
      message: "एपीआई प्रोटोकॉल का उपयोग करके साइट पर बातचीत करने के लिए एक एपीआई कुंजी उत्पन्न करें",
      buttonUpdate: "एपीआई कुंजी अपडेट करें",
      updateError: "अद्यतन त्रुटि",
      settingsChanged: "सेटिंग्स बदल गई",
      errorChangingSettings: "सेटिंग्स बदलने में त्रुटि",
      phoneNumber: "आपका फ़ोन नंबर",
      playceholderPhone: "अपना नंबर दर्ज करें",
      refParams: {
        refLink: "रेफरल कार्यक्रम रिपोर्ट:",
        refOptions: "रेफरल कार्यक्रम विकल्प:",
      },
    },
    myPersonalInformation: {
      title: "आपका व्यक्तिगत डेटा",
      yourEmail: "आपका ई-मेल",
      yourName: "आपका नाम",
    },
    referralProgram: {
      title: "रेफरल कार्यक्रम",
      referralLinkTitle: "रेफरल लिंक",
      buttonCopy: "कॉपी करें",
      buttonDownload: "डाउनलोड करें",
      topUpAmount: "अपने रेफरल द्वारा खर्च",
      youHaveAlreadyReceived: "रेफरल भुगतान प्राप्त हुआ",
      awaiting: "शेष रेफरल शेष",
      derived: 'उनमें से-व्युत्पन्न',
      detailing: "रेफरल सिस्टम रिपोर्ट",
      formTitle: "एक आवेदन जमा करें",
      formButton: "निकासी अनुरोध",
      participatePromotion: "पदोन्नति में भाग लें",
      earnedYourReferrals: "अपने रेफरल द्वारा अर्जित",
      instructions: "इस पंजीकरण लिंक को किसी मित्र को भेजें या ट्रैफ़िक मध्यस्थता के लिए इसका उपयोग करें ।  अनुरोध पर, शेष राशि से रेफरल शुल्क वापस लें — डिफ़ॉल्ट रेफरल टर्नओवर का 5% — एक तरह से जो आपके लिए सुविधाजनक है ।  अर्जित धन संख्याओं की खरीद पर खर्च किया जा सकता है (99 से राशि का हस्तांतरण समर्थन सेवा के अनुरोध पर किया जाता है) या कार्ड या इलेक्ट्रॉनिक वॉलेट में वापस ले लिया जाता है ।  एक क्रिप्टो वॉलेट से निकासी — 100 यूएसडीटी से, एक कार्ड से-5,000 से । "
    }
  },
  profilePay: {
    sectionBalanceReplenishment: {
      title: "संतुलन पुनःपूर्ति",
      cardButtonSelect: "का चयन करें",
      paymentTitle: "भुगतान",
      paymentMessage: "हमेशा पे बटन पर क्लिक करके साइट इंटरफेस के माध्यम से भुगतान करें, तभी आप सही विवरण में धन हस्तांतरित करेंगे",
      paymentMessageAlipay: "सेवा केवल सत्यापित चीनी मुख्य भूमि में उपयोगकर्ताओं का समर्थन करती है ।  आप नीचे सभी अलीपे उपयोगकर्ताओं के लिए एक विधि आज़मा सकते हैं.",
      paymentPlaceholder: "राशि दर्ज करें *",
      paymentButton: "वेतन",
      paymentErrorAmount: "टॉप-अप राशि 0 से अधिक होनी चाहिए",
      payComment: "पुनःपूर्ति के लिए आयोग",
      plasticCard: "बैंक कार्ड: केवल रूस",
      minAmount: "न्यूनतम भुगतान राशि {minPrice} ₽",
      minAmountMessage: "न्यूनतम भुगतान राशि {minPrice} ₽",
      payMessageForPayeer: "चयनित पुनःपूर्ति विधि, grizzlysms.com सेवाएं थ्रीटवॉटो लिमिटेड द्वारा प्रदान की जाती हैं ।  जारी रखने के लिए, आपको नीचे वर्णित दस्तावेजों से सहमत होना होगा.",
      payMessageForPaypalych: "बैंक कार्ड: केवल रूस  रूस (alt)",
      som: 'बैंक कार्ड: पूरी दुनिया में-विधि संख्या 2',
      payMessageForNut: "बैंक कार्ड: पूरी दुनिया में-विधि संख्या 3",
      stripe: "बैंक कार्ड: पूरी दुनिया में-विधि संख्या 1 (Stripe)",
      bankCardsTemplateRussia: "बैंक कार्ड: केवल रूस",
      payMessageForTether: "महत्वपूर्ण: भुगतान प्रणाली द्वारा इंगित की जाने वाली राशि को ठीक से स्थानांतरित करें ।  अन्यथा, धन जमा नहीं किया जाएगा और धनवापसी की लागत $10 होगी.",
      alipayTitle: "अलीपे स्कैन क्यूआर-कोड द्वारा भुगतान करने के लिए:",
      alipayText: "...और हमें अपना पंजीकरण ईमेल पता लिखें और लाइव चैट के लिए भुगतान का स्क्रीनशॉट भेजें (↘).<br/>आपका बैलेंस अपने आप टॉप अप नहीं हो सकता ।  कृपया हमें लिखें.<br/>न्यूनतम भुगतान 10 आरएमबी है.",
      paymentMessageForConvertor: "भुगतान की जाने वाली राशि, {currency}:",
      paymentMessageForConvertorWithCommission: "कमीशन सहित भुगतान की जाने वाली राशि, {currency}:",
      willBeCredited: "जमा किया जाएगा, {currency}:",
      messageForConverterDollars: "हम डॉलर स्वीकार करते हैं और उन्हें दर पर रूबल में परिवर्तित करते हैं: <span> {rate} </span> ₽ प्रति डॉलर.",
      getNumbersFor: 'के लिए नंबर मिल रहा है {name}',
      usdHelpMessage: 'जानकारी संदर्भ के लिए प्रदान की गई है ।  कृपया भुगतान साधन के इंटरफ़ेस में इंगित राशि को प्राथमिकता के रूप में मानें ।  विनिमय दर में उतार-चढ़ाव के कारण, राशियाँ थोड़ी भिन्न हो सकती हैं.'
    },
    aliPay: {
      title: "अलीपे और क्यूक्यूपेमेंट",
      messageStep1: "1. नीचे दिए गए लिंक पर जाएं, एक राशि चुनें और इसके लिए भुगतान करें:",
      messageStep2: "2. अपने ग्रिजली एसएमएस बैलेंस पर पैसे जोड़ने के लिए सशुल्क कोड दर्ज करें:",
      send: "भेजें",
      couponNotFound: "कूपन नहीं मिला",
      placeholderPassword: "जोड़ने के लिए कोड दर्ज करें",
      waitingForRedirectPayment: "भुगतान करने के लिए साइट पर संक्रमण की प्रतीक्षा करें",
      paymentCodeNotCorrect: "भुगतान कोड सही नहीं है",
      buttonGetLink: "अलीपे या क्यूक्यू के माध्यम से भुगतान करें एक कोड प्राप्त करें",
    },
    cryptoPayments: {
      label: "क्रिप्टो भुगतान"
    }
  },
  servicesList: {
    "Выбор_страны": "देश चयन",
    "Выбор_страны_для": "के लिए देश चयन {name}",
    title: "देश का चयन करें",
    titlePopular: "लोकप्रिय देश",
    chooseAnother: "दूसरा चुनें",
    countryInputPlaceholder: "देश का चयन करें",
    selectedLable: " का चयन करें",
    searchInputPlaceholder: "सेवा की खोज",
    buttonWaiting: "प्रतीक्षा कर रहा है...",
    buttonBuy: "प्राप्त करें",
    buttonRent: "किराया",
    message: 'वर्तमान में चयनित देश के लिए कोई संख्या उपलब्ध नहीं है',
    buttonUpdate: 'अद्यतन करें',
    quantity: 'मात्रा',
    quantityFew: 'कुछ',
    numberTemporarilyUnavailable: "केवल एपीआई द्वारा उपलब्ध है",
    orderFrom100numbers: "समर्थन सेवा के माध्यम से 100 नंबरों से ऑर्डर करें",
    labelCountryHighPercentDelivery: "उच्च % वितरण वाले देश",
    labelSelectOtherCountry: "दूसरा देश चुनें",
    showMore: "शोअधिक",
    from: 'से',
    retail: 'खुदरा',
    authRetail: 'आपकी कीमत',
    wholesale: 'थोक',
    getItFor: "इसके लिए जाओ",
    telegramMessagePrice: 'थोक मूल्य जानकारी:',
    telegramMessageBot: 'टेलीग्राम मिनी ऐप:',
    telegramMessageNews: 'सेवा समाचार:',
    scrollBtn: 'सभी सेवाओं और देशों',
    selectCountry: "देश चुनें",
    selectService: "सेवा का चयन करें",
    relatedArticlesFor: "«{name}» के बारे में लेख",
    requestNewServiceForm: {
      title: 'एक सेवा प्रदान करें',
      subTitle: "सही सेवा नहीं मिली? हमें इसके बारे में नीचे बताएं और हम स्थिति को ठीक कर देंगे. <br /> और अभी हम अनुभाग का उपयोग करने की पेशकश करते हैं",
      anyother: 'अन्य',
      dataAboutSite: 'वेबसाइट का पता *',
      smsMessage: 'एसएमएस पाठ *',
      countryService: 'वांछित देश (not necessary)',
      price: 'संख्या मूल्य (not necessary)',
      btnSend: 'भेजें',
      backToList: 'सूची में',
      offerServiceBtn: "एक सेवा प्रदान करें",
    },
    linkToAccount: {
      btnMsg: "{accountName} खाते",
      text: "* रेडी-मेड के साथ काम करना {accountName} खातों को पीसी कौशल की आवश्यकता होती है; उपलब्ध देशों का सेट खातों के स्व-पंजीकरण के लिए देशों के सेट से भिन्न होता है"
    }
  },
  languagesList: {
    "Выбор_сервиса": "सेवा चयन",
    "Поиск_по_сервисам": "सेवाओं द्वारा खोजें",
    "Выберите_услугу": "एक सेवा चुनें",
    "Поиск_по_странам": "देश द्वारा खोजें",
    "Успешная_покупка_номера": "नंबर सफलतापूर्वक खरीदा गया है ।  जल्द ही यह आपके में दिखाई देगा \"प्राप्त संख्या\"",
    serviceNotFound: 'सेवा नहीं मिली',
    repeatMsg: 'रुको < {min} min',
    repeatModal: {
      attempt: 'प्रयास',
      from: 'से',
      "Долгое_ожидание": 'लंबा इंतजार?',
      "Предложение_ускорить_поиск": 'चलो, अधिक महंगा कमरा ढूंढने की कोशिश करते हैं। एसएमएस प्राप्त होने के बाद ही धनराशि अंतिम रूप से डेबिट की जाएगी। आप किसी भी समय नंबर को रद्द कर सकते हैं या उसे सेवा एप्लीकेशन में दर्ज नहीं कर सकते हैं। अधिभार जितना अधिक होगा, कमरा शीघ्र मिलने की संभावना उतनी ही अधिक होगी।',
      lowBalanceMessage: 'अपर्याप्त कोष',
    },
    errorMessages: {
      "NO_NUMBERS": "संख्या अनुपलब्ध है ।  फिर से प्रयास करें",
      abort: "नंबर खोज बंद कर दिया",
    }
  },
  blog: {
    title: 'ब्लॉग',
    buttonDetails: "अधिक जानकारी",
    pageNumber: "पृष्ठ №",
    tags: 'टैग',
    recentPost: 'हाल की पोस्ट',
    readMore: 'इसी तरह के लेख',
    getNumberFor: "के लिए नंबर प्राप्त करें"
  },
  footer: {
    title: "ऑनलाइन एसएमएस संदेश प्राप्त करने के लिए सबसे अच्छी सेवा",
    userAgreement: "उपयोगकर्ता समझौता",
    referralProgram: "रेफरल कार्यक्रम",
    returnsPolicy: "रिटर्न पॉलिसी",
    personalInformation: "व्यक्तिगत जानकारी",
    messageDisabledRussianNumbers: "रूस से फोन नंबर बिक्री के लिए उपलब्ध नहीं हैं",
    footerDisabledRussianNumbers: "संघीय कानून की आवश्यकताओं के कारण अस्थायी उपयोग के लिए रूसी मोबाइल ऑपरेटरों की ग्राहक संख्या प्रदान नहीं की जाती है \"संचार पर\" दिनांक 07.07.2003 एन 126-एफजेड और सार्वजनिक संचार नेटवर्क के केंद्रीकृत प्रबंधन के लिए नियमों के पैरा 5 के उप-अनुच्छेद जी के आधार पर भी, 12 फरवरी, 2020 एन 127 को रूसी संघ की सरकार के डिक्री द्वारा अनुमोदित \"सार्वजनिक संचार नेटवर्क के केंद्रीकृत प्रबंधन के लिए नियमों के अनुमोदन पर\""
  },
  registration: {
    title: 'पंजीकरण',
    registrationSuccessMessage: "उपयोगकर्ता सफलतापूर्वक पंजीकृत! <br/> पंजीकरण की पुष्टि के लिए एक लिंक मेल पर भेजा गया है",
    nameInputPlaceholder: 'नाम *',
    emailInputPlaceholder: 'ई-मेल *',
    passwordInputPlaceholder: 'पासवर्ड *',
    passwordConfirmInputPlaceholder: 'पासवर्ड की पुष्टि करें *',
    buttonRegistration: 'पंजीकरण',
    buttonRegistrating: 'प्रतीक्षा कर रहा है...',
    passwordsMismatchMessage: "पासवर्ड बेमेल",
    backHome: 'वापस होमपेज',
    loginWithSocialNetworks: "सामाजिक नेटवर्क के साथ लॉगिन करें:",
    orRegistrartion: "या रजिस्टर करें",
    iAgree: "मैं सहमत हूं",
    privacyPolicy: "व्यक्तिगत डेटा और कुकी नीति के साथ",
    userAgreement: "सार्वजनिक प्रस्ताव के साथ",
    alreadyHaveAccount: "क्या आपके पास पहले से खाता है?",
    login: "लॉग इन करें",
    messageSuccessConfirmRegistration: "उपयोगकर्ता सफलतापूर्वक पंजीकृत",
  },
  authorization: {
    title: "प्राधिकरण",
    emailInputPlaceholder: 'ई-मेल',
    passwordInputPlaceholder: 'पासवर्ड',
    tokenInputPlaceholder: 'कोड',
    buttonRegistration: 'प्राधिकरण',
    buttonRegistrating: 'प्रतीक्षा कर रहा है...',
    or: 'या',
    newUser: 'क्या आप एक नए उपयोगकर्ता हैं?',
    registration: 'पंजीकरण',
    forgotPassword: 'पासवर्ड भूल गए हैं?'
  },
  resetThePassword: {
    title: "पासवर्ड रीसेट करें",
    buttonReset: 'पासवर्ड रीसेट करें',
    buttonWaiting: 'प्रतीक्षा कर रहा है...',
    emailInputPlaceholder: 'ई-मेल',
    message: "आपका पासवर्ड रीसेट करने के लिए एक लिंक आपके ईमेल पर भेजा गया है ।  कृपया अपना इनबॉक्स देखें और निर्देशों का पालन करें."
  },
  errorsValidationForms: {
    requiredForFill: "भरने के लिए आवश्यक है",
    emailErrorMessage: "अमान्य ईमेल",
    requaredLengthMessage: "वर्णों की न्यूनतम संख्या:"
  },
  advantagesBlock: {
    title: "हमारे फायदे"
  },
  aboutBlock: {
    showMoreBtn: "अधिक जानकारी",
    rollUp: "रोल अप",
  },
  serviceCard: {
    availableNumbers: "उपलब्ध संख्या",
    price: "कीमत",
    buyNow: "खरीदें",
    emptyCountryListMessage: "चयनित सेवा के लिए कोई उपलब्ध संख्या नहीं है.<br /> कृपया बाद में पुनः प्रयास करें"
  },
  feedbackForm: {
    title: "प्रतिक्रिया",
    namePlaceholder: "नाम *",
    emailPlaceholder: "ईमेल *",
    messagePlaceholder: "संदेश *",
    iAgree: "मैं सहमत हूं",
    privacyPolicy: "व्यक्तिगत डेटा और कुकी नीति के साथ",
    userAgreement: "सार्वजनिक प्रस्ताव",
    buttonSend: 'भेजें',
    buttonRegistrating: 'प्रतीक्षा कर रहा है...',
    sendError: "त्रुटि भेजें",
    applicationIsAccepted: "आपका आवेदन स्वीकार किया जाता है"
  },
  copyToBuffer: {
    success: "पाठ सफलतापूर्वक कॉपी किया गया",
    error: "कॉपी त्रुटि",
  },
  breadcrumbs: {
    homePage: "मुखपृष्ठ",
    blog: "ब्लॉग",
  },
  confirm: {
    passwordReset: "पासवर्ड रीसेट",
    newPassword: "नया पासवर्ड",
    confirmPassword: "पासवर्ड की पुष्टि करें",
    confirm: "पुष्टि करें",
    authorization: "प्राधिकरण",
    notMatch: "पासवर्ड मेल नहीं खाता",
    changedSuccessfully: "पासवर्ड सफलतापूर्वक बदल गया",
  },
  errorPage: {
    pageNotFound: 'पृष्ठ नहीं मिला',
    somethingWentWrong: 'कुछ गलत हो गया',
    backHomepage: 'वापस होमपेज'
  },
  support: {
    inputSearch: {
      placeholder: "एक प्रश्न दर्ज करें",
    }
  },
  successPayment: {
    successMsg: "भुगतान सफल रहा",
    waitMsg: "भुगतान प्रगति पर है",
    backHomepage: 'वापस होमपेज'
  },
  searchPage: {
    placeholder: 'खोज...',
    showMore: "अधिक दिखाएँ",
    nothingFoundMsg: "आपके अनुरोध के लिए कुछ भी नहीं मिला",
    'Сервисы': 'सेवाएं',
    'Статьи': "लेख",
    'Страницы': "पृष्ठों",
    'Все результаты': " सभी परिणाम",
  },
  common: {
    "downloadFile": "फ़ाइल डाउनलोड करें",
    "temporarily_unavailable": "अस्थाई रूप से अनुपलब्ध"
  },
  lastArticlesBlock: {
    title: "ब्लॉग",
    textBtn: "हमारा ब्लॉग",
  },
  reviewsBlock: {
    title: 'ग्राहक हमारे बारे में क्या कहते हैं',
    addReviewBtn: 'एक समीक्षा जोड़ें',
    yourName: 'आपका नाम',
    messageTextarea: 'कृपया अपनी समीक्षा जोड़ें',
    sendReview: 'समीक्षा भेजें',
    success: 'समीक्षा सफलतापूर्वक भेजी गई है',
    validNameMsg: 'अपना नाम दर्ज करें',
    validMsg: 'अपनी समीक्षा दर्ज करें',
  },
  currencyToggle: {
    tooltipCurrency: 'यूएसडी में कीमतें संदर्भ के लिए दिखाई जाती हैं ।  संतुलन के साथ संचालन केवल आरयूबी में किया जाता है'
  },
  pagination: {
    showing: 'दिखा रहा है',
    of: 'का',
  },
  additionalCashbackAccrual: {
    message: "<span>+10%</span> मामले में अपने संतुलन के लिए कैशबैक <span>1st</span> से टॉप-अप <span>{{balance}}</span> !",
    buttonCancel: "नहीं, धन्यवाद",
    buttonPayment: "टॉप अप बैलेंस"
  },
  countries: {
    title: 'उपलब्ध देशों की सूची',
    countries: 'देशों',
    popularServices: 'लोकप्रिय सेवाएं',
    anotherServices: 'सेवा चुनें'
  },
  botLinkPage: {
    question: 'क्या आप वास्तव में अपने खाते को जोड़ना चाहते हैं grizzlysms.com टेलीग्राम खाते का उपयोग कर वेबसाइट <span> {userName}</span> बॉट के लिए?',
    ok: 'हाँ',
    cancel: 'रद्द करें',
    success: "टेलीग्राम खाता सफलतापूर्वक जुड़ा हुआ है",
    alreadyHaveBot: 'आपके पास पहले से ही एक टेलीग्राम बॉट जुड़ा हुआ है',
  },
  errors: {
    err1: 'एक त्रुटि हुई है ।  कृपया बाद में पुनः प्रयास करें.'
  },
  partnerPage: {
    becomePartner: "भागीदार बनें",
    popUp: {
      title: "भागीदार बनें",
      yourContactPlaceholder: "आपका संपर्क (Telegram, WeChat, WhatsApp, E-mail, other)",
      countryPlaceholder: "के देश \"origin\" सिम कार्ड की",
      equipmentPlaceholder: "आपके उपकरण",
      equipmentPortsPlaceholder: "हार्डवेयर पोर्ट की संख्या",
      aboutPlaceholder: "हमें आपके बारे में और क्या जानना चाहिए?",
      send: "भेजें",
      accept1: "मैं सहमत हूं",
      accept2: "गोपनीयता नीति और अनुरोधित डेटा के उपयोग के साथ",
      errorForm: "एक त्रुटि हुई, कृपया बाद में पुनः प्रयास करें",
      successForm: "आवेदन सफलतापूर्वक भेजा गया",
    }
  },
  partnerFormBlock: {
    title: "भागीदार बनें",
    contactTitle: "टेलीग्राम",
    contactPlaceholder: "आपका पसंदीदा संपर्क",
    countrySimCard: "फोन नंबर के देश",
    countrySimCardPlaceholder: "अल्पविराम अलग हो गया",
    yourEquipment: "जीएसएम मोडेम ' मॉडल",
    yourEquipmentPlaceholder: "मुझे विस्तार से बताएं",
    countPort: "कॉम-पोर्ट की मात्रा (16 or more)",
    countPortPlaceholder: "संख्याओं में निर्दिष्ट करें",
    message: "हमें आपके बारे में और क्या जानना चाहिए?",
    messagePlaceholder: "अपने व्यवसाय का विस्तार से वर्णन करें",
    accountGrizzlySmsEmail: "Grizzly SMS खाता (email address you used)"
  },
  partnerHeadingBlock: {
    becomePartner: "भागीदार बनें",
    yieldCalculator: "यील्ड कैलकुलेटर"
  },
  partnerCalculatorBlock: {
    country: "देश",
    priceSim: "सिम कार्ड की लागत",
    countPort: "मॉडेम में बंदरगाहों की संख्या",
    netProfit: "लाभ",
    netProfit3: "3 महीने के लिए लाभ",
    netProfit6: "6 महीने के लिए आय",
    netProfit12: "12 महीने के लिए आय",
  },
  generalSearch: {
    searchByCountry: "देश द्वारा खोजें",
    searchByService: "सेवा द्वारा खोजें",
    title: "एसएमएस प्राप्त करने के लिए वर्चुअल नंबर की कीमतें",
    subtitle: "नीचे दी गई तालिका में उस सेवा या देश का चयन करें जिसकी आपको आवश्यकता है",
    tableHeaderService: "सेवा",
    tableHeaderCountry: "देश",
    tableHeaderCurrency: "थोक मूल्य",
    tableHeaderAmount: "मात्रा",
    tableHeaderNumberReceived: "% number",
    tableHeaderSmsReceived: "% sms",
    tableHeaderActivation: "सक्रियण %",
  },
  profileLoyaltyProgram: {
    cashback: {
      title: "कैशबैक",
      week_deposit: "वर्तमान सप्ताह के लिए टॉप-अप राशि",
      projectedCashback: "अनुमानित कैशबैक",
      tillNextRank: "अगली रैंक तक छोड़ दिया",
      cashbackPercentNextRank: "अगली रैंक में कैशबैक",
      rang: "रैंक"
    },
    history: {
      title: "भागीदारी इतिहास",
      message1: "पुनःपूर्ति के लिए",
      message2: "कैशबैक का श्रेय",
    },
    programConditions: {
      title: "वफादारी कार्यक्रम की शर्तें",
      rangLeft: "रैंक: शर्त प्राप्त करना",
      rangRight: "% कैशबैक",
      rangNotLeft: "कोई रैंक नहीं: टॉप-अप > 0 <1,000 ₽",
      rangNotRight: "कोई कैशबैक नहीं",
      message: "कैशबैक उपयोगकर्ताओं को श्रेय दिया जाता है <span>1st सप्ताह का दिन (Monday)</span> पिछले सप्ताह में जमा की कुल राशि के परिणामों के आधार पर ।  कैशबैक का प्रतिशत पिछले सप्ताह के अंत तक उपयोगकर्ताओं द्वारा प्राप्त रैंक पर निर्भर करता है.",
      replenishment: "पुनःपूर्ति",
      cashback: "कैशबैक"
    }
  },
  license: {
    licenseSuccess: 'लाइसेंस खरीदा गया है और आपके ईमेल पर भेजा गया है',
    buyKey: 'एक कुंजी खरीदें GrizzlySMS'
  },
  featuresTextBlock: {
    getIndividualNumber: "एक निजी नंबर प्राप्त करें"
  },
  sortComponent: {
    country: 'शीर्ष देश'
  },
  rentNumbers: {
    typeViews: {
      "number_activation": "सक्रियण",
      "rent_number": "किराया",
      "readyAccounts": "खाते"
    },
    rentInformation: {
      "rental_period": "किराये की अवधि",
      "rental_duration": "किराये की अवधि",
      "rent": "किराया",
      "the_clock": "घंटे के हिसाब से",
      "for_days": "by day",
      "specify_rental_duration": "किराये की अवधि निर्दिष्ट करें",
      "maximum_number_hours": "घंटे की अधिकतम संख्या",
      "minimal_number_hours": "घंटे की न्यूनतम संख्या",
      "number_successfully_rented": "नंबर सफलतापूर्वक किराए पर लिया",
      "selected_time_not_available": "संख्या अनुपलब्ध",
      "available_minimum_rental_period": "उपलब्ध न्यूनतम किराये की अवधि",
      "available_maximum_rental_period": "उपलब्ध अधिकतम किराये की अवधि",
    },
    myRentNumbers: {
      "rent_virtual_number": "वर्चुअल नंबर किराए पर लें",
      "active": "सक्रिय",
      "received": "प्राप्त किया",
      "returned": "रद्द कर दिया",
      "not_found": "नहीं मिला"
    },
    rentNumberCard: {
      "canceled": "रद्द कर दिया",
      "expires_in": "में समाप्त हो रहा है",
      "extension": "विस्तार करें",
      "messageTitle": "संदेश",
      "messageMessage": "अभी तक कोई संदेश या कॉल नहीं आया है ।  नंबर या कॉल पर एक एसएमएस भेजें, और जानकारी तुरंत यहां दिखाई जाएगी.",
      "successCancel": "नंबर रेंटल सफलतापूर्वक रद्द कर दिया गया",
      "successNumberRenewal": "नंबर रेंटल सफलतापूर्वक बढ़ाया गया",
      "rentalPeriodExpired": "समाप्त हो गई"
    },
    readyNumbers: {
      message: 'महत्वपूर्ण! यदि आप शुरू में तैयार खाते खरीद रहे हैं, तो आपको जानकारी की समीक्षा करनी होगी <a href="{link}"> "खाते"</a> "समर्थन" पर अनुभाग (FAQ)" पृष्ठ.',
      ok: 'मैं सहमत हूं',
      toFaq: 'एफएक्यू',
      cancel: 'रद्द करें'
    },
    popUpExtendLease: {
      "extend_number_rental": "संख्या किराया बढ़ाएँ",
      "going_renew_lease_number": "क्या आप एक नंबर पर अपने पट्टे को नवीनीकृत करने जा रहे हैं?",
      "renewal_history": "नवीनीकरण इतिहास",
      "date": "तारीख",
      "price": "कीमत",
      "term_hours": "घंटों में समय सीमा",
      "number_rental_renewal_period": "संख्या किराये की नवीकरण अवधि",
      "lease_successfully_extended_until": "पट्टा सफलतापूर्वक बढ़ाया गया था जब तक",
      "close": "बंद करें",
      "short_day": "दिन",
      "short_hours": "घंटा.",
      "short_minutes": "मिन."
    },
    helpPopUp: {
      message: `<h4>संख्या सक्रियण</h4>
         <p>यदि आपको पहले 20 मिनट के भीतर कोड वाला एसएमएस नहीं मिलता है, तो आप नंबर को रद्द कर सकते हैं, पैसा आपके खाते में पूर्ण रूप से वापस कर दिया जाएगा.</p>

         <h4>एक नंबर किराए पर लें</h4>
         <p>चयनित अवधि के दौरान, आपके पास हमेशा चयनित सेवा से असीमित संख्या में एसएमएस प्राप्त करने का अवसर होगा.</p>
         <p>आप हमेशा अपने पंजीकृत खाते तक पहुंच बहाल कर सकते हैं!</p>
         <p>यदि आपको पहले 20 मिनट के भीतर कोड वाला एसएमएस नहीं मिलता है, तो आप नंबर को रद्द कर सकते हैं, पैसा आपके खाते में पूर्ण रूप से वापस कर दिया जाएगा.</p>
         <p>यदि आपको एसएमएस नहीं मिला है और 20 मिनट से अधिक समय बीत चुका है, तो किराये को रद्द करना असंभव होगा.</p>
         <p>पट्टे की अवधि समाप्त होने पर संबंधित सेवा के लिए किराए के लिए बेची गई संख्या को फिर से नहीं बेचा जाएगा.</p>
         <p>न्यूनतम किराये की अवधि 4 घंटे है, अधिकतम 4 सप्ताह है.</p>
         <p>एक नंबर किराए पर लेने के दो विकल्प हैं:</p>
         <p>पूर्ण किराया-इस मामले में, आप सभी एसएमएस प्राप्त कर सकते हैं, जिनकी सेवाएं हमारे कैटलॉग में प्रस्तुत की गई हैं.</p>
         <p>एक विशिष्ट सेवा के लिए एक नंबर किराए पर लें ।  ऐसे में आपको अपने द्वारा चुनी गई सेवा से ही एसएमएस प्राप्त होगा.</p>

         <h4>खाते</h4>
         <p>एसएमएस के इंतजार में अपना समय बर्बाद नहीं करना चाहते? आप हमारे भागीदारों द्वारा तैयार किया गया खाता खरीद सकते हैं ।  टेलीग्राम, वीके और अन्य सेवाओं के लिए खाते आमतौर पर उपलब्ध हैं.</p>
         <p>टेलीग्राम खाते 2 प्रारूपों में प्रदान किए जाते हैं: टीडीएटीए (an account in this format can be logged into on a PC and later transferred to a mobile device) और जेसन + सत्र (in this case you will need special software).</p>
         <p>तैयार खातों के साथ काम करने का विवरण, साथ ही वापसी की स्थिति, एफएक्यू अनुभाग में वर्णित हैं (the “Support” button in the site menu). खरीदने से पहले इस जानकारी को अवश्य पढ़ें!</p>
      `
    }
  },
  comments: {
    "add_comment": "एक टिप्पणी जोड़ें",
    "comments": "टिप्पणियाँ",
    "collapse": "संक्षिप्त करें",
    "expand": "विस्तार करें",
    "send_comment_moderation": "आपकी टिप्पणी मॉडरेशन के लिए भेजी गई है.",
    "ony_auth_user": "केवल पंजीकृत सदस्यों के लिए उपलब्ध है"
  },
  saleAgreement: {
    "title": "लाइसेंस की बिक्री",
    "name": "नाम",
    "type_po": "सॉफ्टवेयर प्रकार",
    "price": "कीमत",
    "date_purchase": "खरीद की तारीख",
  },
  freeNumbersPopUpRegistration: {
    subtitle: "एक मुफ्त नंबर के साथ एक खाते का निर्माण काम नहीं कर रहा था? साइन अप करें और सेवा के लिए एक निजी नंबर चुनें — <span>से $0.01!</span>",
    button: "कोशिश करो"
  },
  accountShop: {
    mainSectionInfo: {
      my_paid_accounts_title: "मेरे भुगतान किए गए खाते",
      my_paid_accounts_message: "यहां आपको कभी खरीदे गए सभी खातों की सूची और आवश्यक लॉगिन जानकारी दिखाई देती है",
      my_paid_accounts_button: "क्रय खाते",

      purchasing_ready_accounts_title: "क्रय खाते",
      purchasing_ready_accounts_message: "हम समझते हैं कि 20 मिनट के भीतर एसएमएस प्राप्त करना हमेशा संभव नहीं होता है. इस खंड में आप किसी भी लोकप्रिय सेवा के लिए तैयार खाता खरीद सकते हैं.",
      purchasing_ready_accounts_button: "मेरे भुगतान किए गए खाते",
    },
    selectedService: {
      title: "सेवा का चयन करें",
      search_placeholder: "सेवा नाम से खोजें"
    },
    selectedOffer: {
      title: "प्रस्ताव का चयन करें"
    },
    dialogOffer: {
      title: "खाता खरीदें",
      name_of_product: "उत्पाद का नाम",
      description_of_product: "उत्पाद विवरण",
      characteristics: "विशेषताएँ",
      registration_method: "पंजीकरण विधि",
      country: "देश",
      account_format: "खाता प्रारूप",
      availability_cloud_password: "क्लाउड पासवर्ड",
      information_about_purchased_product: "खरीदे गए उत्पाद की जानकारी",
      download_files: "फ़ाइलें डाउनलोड करें",
      buy_more: "अधिक खरीदें",
      yes: "हाँ",
      no: "नहीं।",
      minimum_order_quantity: "न्यूनतम आदेश मात्रा"
    },
    myServices: {
      messageAuth: "आपको अपने अकाउंट में लॉग इन करना होगा",
      messageEmpty: "कोई खरीदे गए खाते नहीं"
    }
  },
  partner: {
    breadcrumbs_title: "साथी",
    documentation: "प्रलेखन",
    activations_history: {
      activations_history: "सक्रियण इतिहास",
      filter: "फ़िल्टर करें",
      export_in: "को निर्यात करें",
      number: "संख्या",
      load_more: 'अधिक दिखाएँ',
      total_rows: 'कुल: {{ count }} पंक्तियाँ',
      select_status: 'स्थिति का चयन करें',
      date_activation: 'सक्रियण तिथि',
      date_from: 'तारीख से',
      date_to: 'तिथि करने के लिए',
      ready: 'तैयार है',
      canceled: 'रद्द कर दिया',
      activation: "सक्रियण"
    },
    sim_card: {
      sim_card: "सिम कार्ड",
      port: 'पोर्ट',
      info: 'जानकारी',
      earned: 'अर्जित किया',
      countServices: 'सेवाओं की गणना करें'
    },
    price_regulator: {
      price_regulator: "मूल्य नियामक",
      select_country_started: "आरंभ करने के लिए किसी देश का चयन करें",
      services: "सेवाएं",
      search_service: "सेवा द्वारा खोजें",
      all_services: "सभी सेवाएं",
      all_countries: "सभी देशों",
      popular_services: "लोकप्रिय",
      market_price: "बाजार मूल्य निर्धारित करें",
      competitors_prices: "प्रतियोगी कीमतें दिखाएं",
      pice_successfully_changed: "मूल्य सफलतापूर्वक बदल गया",
    },
    withdrawal_founds: {
      withdrawal_founds: 'निकासी पाया गया',
      balance: 'आपका संतुलन',
      order_payments: 'भुगतान का आदेश दें',
      currency_type: 'मुद्रा प्रकार',
      withdrawal_method: 'निकासी विधि',
      order_status: 'आदेश की स्थिति',
      select_method: 'एक विधि का चयन करें',
      select_status: 'स्थिति का चयन करें',
      requisites: 'आवश्यक वस्तुएँ',
      sum: 'योग',
      currency: 'मुद्रा',
      date: 'तारीख',
      status: 'स्थिति',
      nothing_found: 'कुछ नहीं मिला',
      amount_in_currency: 'मुद्रा में राशि',
      wallet_card_number: 'वॉलेट / कार्ड नंबर',
      comment: 'टिप्पणी',
      not_necessary_placeholder: 'जरूरी नहीं',
      all_btn: 'सब कुछ',
      error_required: 'सभी आवश्यक फ़ील्ड भरें',
      success_msg: 'आपका आवेदन स्वीकार किया जाता है',
      select_currency_type: "मुद्रा प्रकार चुनें",
      warning_message: '<span>महत्वपूर्ण!</span> सुनिश्चित करें कि आपने ऊपर अपना आवेदन बनाते समय सही भुगतान विवरण दर्ज किया है. का प्रशासन grizzlysms.com गलत भुगतान विवरण के कारण धन की हानि के मामले में जिम्मेदार नहीं है.'
    },
    statistic: {
      statistic: 'मुद्रीकरण आँकड़ा',
      average_SIM: 'औसत <br/> प्रति सिम',
      top_services: 'शीर्ष सेवाएं <br/>24 घंटे के लिए',
      activations_count: 'सक्रियण गिनती <br />24 घंटे के लिए',
      last_24_hours: 'पिछले के लिए <br/>24 घंटे'
    },
    dialog_competitors_prices: {
      your_price: "आपकी कीमत",
      competitors_prices: "प्रतियोगियों की कीमतें"
    },
  },
  newPayments: {
    minAmount: 'न्यूनतम भुगतान राशि:',
    commission: 'आयोग:',
  },
  soft: {
    "submit_application": "आवेदन जमा करें",
    "search_by_name": "नाम से खोजें",
    "all_services": "सब कुछ<br/>सेवाएं",
    "activations": "सक्रियण"
  },
  productLicensesBlock: {
    availableAt: "पर उपलब्ध है",
  },
  documentationApi: {
    rent: "एपीआई किराया",
    tables: "टेबल्स",
    activations: "एपीआई सक्रियण",
    expandContent: "सामग्री का विस्तार करें",
    collapseContent: "सामग्री संक्षिप्त करें",
    successLinkCopy: "लिंक सफलतापूर्वक कॉपी किया गया",
    successApiKeyCopy: "एपीआई कुंजी सफलतापूर्वक कॉपी की गई",

    select: "का चयन करें",
    fillIn: "भरें",
    result: "परिणाम",
    execute: "निष्पादित करें",
    urlRequest: "अनुरोध यूआरएल",
    resultLoading: "परिणाम लोड हो रहा है...",
    testMethodNow: "अब इस विधि का परीक्षण करें"
  },
  marketplaceRegistrationPage: {
    pageTitle: "स्टोर पंजीकरण",
    formInputs: {
      name: {
        label: 'अपने स्टोर के लिए एक नाम लेकर आएं ।  शायद जिसे आप कहते हैं वह आपके उत्पाद के सार को प्रतिबिंबित करेगा और चुनते समय खरीदार की मदद करेगा.',
        placeholder: 'नाम',
        errorMessage: 'भरने के लिए आवश्यक है'
      },
      shopDescription: {
        label: 'स्टोर विवरण',
        placeholder: 'स्टोर के विवरण के साथ आओ, इसकी विशिष्टता और विशेषताओं को उजागर करें...',
        errorMessage: 'भरने के लिए आवश्यक है'
      },
      email: {
        label: 'आपका ईमेल',
        placeholder: 'प्रतिक्रिया के लिए कृपया अपना ईमेल पता दर्ज करें...',
        errorMessageForNull: 'भरने के लिए आवश्यक है',
        errorMessageNoValid: 'अमान्य ईमेल पता'
      },
    },
    formSendButton: 'एक स्टोर पंजीकृत करें',
    rulesMarket: {
      iAgree: 'मैं सहमत हूं',
      rulesPhrase: 'Grizzlysms.com मार्केटप्लेस नियम और विनियम'
    },
    offerAgreement: {
      iAccept: 'मैं स्वीकार करता हूं',
      offerPhrase: 'प्रस्ताव समझौता'
    },
    toastSuccessMessage: 'आपका आवेदन भेज दिया गया है'
  },
  bannerForBuyAccounts: {
    title: 'क्या एसएमएस द्वारा सक्रिय करना मुश्किल है?',
    subtitle: 'तैयार खाता खरीदें, यह बहुत आसान है!',
    button: 'तैयार खाता खरीदें'
  }
}
